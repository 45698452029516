<template>
    <div class="v-contract">
        <div class="container">
            <div class="pages">
                <div
                    v-for="(page, index) in pages"
                    :key="page.id"
                    class="page"
                >
                    <img :src="page.image" :alt="`page-${index}`">
                    <div
                        v-for="item in page.field"
                        :key="item.id"
                        class="field"
                        :style="`top: ${+item.y + 5}px; left: ${+item.x + 5}px;font-size: ${item.font_size}px;font-style: ${item.font_style};font-weight: ${item.font_weight};`"
                    >
                        {{ item.value }}
                    </div>
                </div>
            </div>
        </div>
        <div class="panel">
            <div class="recipient-btns" v-if="!isBroker">
                <div
                    class="panel-btn reject"
                    @click="$store.commit('popups/show', 'reject')"
                >
                    <img src="@/assets/not_interested.svg" alt="not_interested">
                    <span>reject</span>
                </div>
                <div
                    class="panel-btn offer"
                    @click="$store.commit('popups/show', 'offer')"
                >
                    <img src="@/assets/edit.svg" alt="not_interested">
                    <span>
                        counter<span class="desctop-text">-</span>offer
                    </span>
                </div>
                <div
                    class="panel-btn sign"
                    @click="redirect"
                >
                    <img src="@/assets/check.svg" alt="not_interested">
                    <span>
                        approve
                        <span class="desctop-text">& sign</span>
                    </span>
                </div>
            </div>
            <div class="broker-btns" v-if="isBroker">
                <div
                    class="panel-btn forward"
                    @click="$store.commit('popups/show', 'recipients')"
                >
                    <img src="@/assets/edit.svg" alt="not_interested">
                    <span>
                        forward recipients
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: function() {
        return {
            pages: [],
        }
    },
    beforeUpdate() {

        console.log("UPDATE")
        // let { position } = this.$route.params
        let isBroker = window.localStorage.getItem("position")
        let isBrokerPath = this.$router.currentRoute.path.includes("broker")

        console.log(this.$router.currentRoute.path.includes("broker"))

        if(isBroker && !isBrokerPath)
            this.$router.push(`/contract/1/TVRJMmZERjhPRFU9fGRHVnpkSEZpYzJsbmJuTmhiSFE9/broker`)

    }, 
    async mounted() {
        try {
            let { key, position } = this.$route.params
            // let isBroker = window.localStorage.getItem("position")
            // let isBrokerPath = this.$router.currentRoute.path.includes("broker")

            // console.log(this.$router.currentRoute.path.includes("broker"))

            // if(isBroker && !isBrokerPath)
            //     this.$router.push(`/contract/1/TVRJMmZERjhPRFU9fGRHVnpkSEZpYzJsbmJuTmhiSFE9/broker`)

            if(position)
                window.localStorage.setItem("position", "broker")

            let response = await fetch(`https://qbsign.com/api/document/user?user_key=${key}`)

            response = await response.json()

            this.pages = response.pages
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        isBroker() {
            return this.$route.params.position === "broker"
        },
    },
    methods: {
        async redirect() {
            let { key, rid } = this.$route.params
            
            let recipientFid = atob(atob(key).split("|")[0]).split("|")[2]

            let fid = `fid_${recipientFid == 85 ? "99" : "100"}`
            
            await fetch(`https://offers-dev.genstonerealty.com/api/saveFields.php`, {
                method: "POST",
                body: JSON.stringify({
                    [fid]: "Opened",
                    dbid: "brd8g7nm7",
                    rid,
                })
            })

            window.location.replace(`https://qbsign.com/panel#/sign-contract/${key}`)
        },
    },
}

</script>

<style lang="sass">

div.v-contract

    div.pages
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        padding-top: 20px

        img
            max-width: 800px
            width: 100%

    div.page
        position: relative
        margin-bottom: 10px

    div.recipient-btns
        display: flex
        justify-content: center
        align-items: center

    div.panel
        position: fixed
        bottom: 0
        left: 0
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        background: #201648
        padding: 10px
        color: #fff
        z-index: 200

        div.panel-btn
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12)
            background: #fff
            border-radius: 3px
            margin: 0 10px
            width: 180px
            display: flex
            justify-content: center
            align-items: center
            cursor: pointer
            text-transform: uppercase
            font-weight: 600
            font-size: 14px
            line-height: 22px
            letter-spacing: 0.02em
            padding: 5px
            min-height: 44px

            img
                margin-right: 6px

            &.reject
                color: #DC3545

            &.offer
                color: #F77B00

            &.sign
                color: #28A745

            &.forward
                color: #F77B00
                width: 200px

    div.initials,
    div.signature
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        min-width: 100px
        min-height: 100px
        border: 1px solid orange
        padding: 15px
        z-index: 100
        font-size: 13px

    div.initials
        min-width: 100px

    div.signature
        min-width: 150px

    div.field
        position: absolute
        z-index: 100

    @media screen and (max-width: 620px)
        div.panel-btn
            width: 30%

            span.desctop-text
                display: none

</style>